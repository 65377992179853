$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$sm-laptop-device: 'only screen and (min-width: 1300px) and (max-width: 1599px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$custom-lg-layout: 'only screen and (min-width: 1021px) and (max-width: 1090px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';


// .slick-btn {
//     position: absolute;
//     top: 50%;
//     display: block;
//     width: 20px;
//     height: 20px;
//     padding: 0;
//     -webkit-transform: translate(0, -50%);
//     -ms-transform: translate(0, -50%);
//     transform: translate(0, -50%);
//     cursor: pointer;
//     color: transparent;
//     border: none;
//     outline: none;
//     background: transparent;
//     left: 50px;
//     z-index: 9;
//
//     &::before {
//       display: none;
//     }
//
//     @media #{$md-layout} {
//       left: 16px;
//     }
//
//     @media #{$sm-layout} {
//       left: 16px;
//     }
//
//     i {
//       color: #ffffff;
//       font-size: 20px;
//       -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1), visibility .3s linear 2s, opacity .3s linear 2s;
//       transition: all .3s cubic-bezier(.645, .045, .355, 1), visibility .3s linear 2s, opacity .3s linear 2s;
//     }
//
//     &:hover {
//       i {
//         color: var(--clr-theme);
//       }
//     }
//
//     &.slick-next {
//       right: 50px;
//       left: auto;
//
//       @media #{$md-layout} {
//         left: auto;
//         right: 16px;
//       }
//
//       @media #{$sm-layout} {
//         left: auto;
//         right: 16px;
//       }
//     }
//   }
//
//   &:hover {
//     .slick-btn {
//       opacity: 1;
//     }
//   }
// }

.slick-dots {
  bottom: 0;
}

.bk-carousel-slider {
  // padding-top: 312px;
  overflow-x: hidden;
  width: 100%;
  // max-width: 1080px;
  margin: 0 auto;
  position: relative;
}

.carousel-slider {
  padding: 1rem;
}

.carousel-slider .content {
  // background: #fff;

  // transform: translateY(-50px);
  padding: 1rem;
  // background: linear-gradient(180deg, rgb(218 219 184) 0%, rgba(245,239,188,1) 17%, rgb(252 255 226) 100%);
}

.carousel-slider .content h5 {
  // font-family: "Unifraktur Maguntia" !important;
  font-family: 'OldStyle 1 HPLHS' !important;
  text-transform: uppercase;
  // display: flex;
  align-items: center;
  // padding: 1rem;
  // margin: 0;

}

.slick-slide {
}

.carousel-slider .content p {

  // font-size: 1.5rem !important;
  padding: 1rem;
  display: none;
}

.slick-btn.slick-arrow i {
  font-size: 30px;
  color: #f10;
}

.slick-arrow-center {
  position: relative;

  .slick-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    left: -70px;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    z-index: 2;

    &::before {
      display: none;
    }

    i {
      background: transparent;
      color: var(--clr-body);
    }

    @media #{$md-layout} {
      left: -10px;
    }

    @media #{$lg-layout} {
      left: -10px;
    }

    @media #{$sm-layout} {
      left: -10px;
    }

    &.slick-next {
      left: auto;
      right: -70px;

      @media #{$lg-layout} {
        left: auto;
        right: -10px;
      }

      @media #{$md-layout} {
        left: auto;
        right: -10px;
      }

      @media #{$sm-layout} {
        left: auto;
        right: -10px;
      }
    }
  }

  &:hover {
    .slick-btn {
      opacity: 1;
      visibility: visible;
    }
  }

  &.slick-arrow-rounded {
    &.lr-0 {
      .slick-btn {
        left: 25px;

        &.slick-next {
          left: auto;
          right: 25px;
        }
      }
    }

    .slick-btn {
      background-color: #fff;
      width: 68px;
      height: 68px;
      font-size: 20px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      color: rgba(34, 34, 34, 0.3);
      left: -14px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
      border-radius: 50%;
      z-index: 2;
      text-align: center;
      line-height: 75px;
      padding: 0;

      i {
        background: transparent;
        color: var(--clr-body);
      }

      @media #{$md-layout} {
        left: 0px;
      }

      @media #{$lg-layout} {
        left: 0px;
      }

      @media #{$sm-layout} {
        left: 0px;
      }

      &:hover {
        background-color: #0038E3;
        color: #fff;

        i {
          color: #fff;
        }
      }

      &.slick-next {
        left: auto;
        right: -14px;

        @media #{$lg-layout} {
          left: auto;
          right: 0px;
        }

        @media #{$md-layout} {
          left: auto;
          right: 0px;
        }

        @media #{$sm-layout} {
          left: auto;
          right: 0px;
        }
      }
    }
  }

  &.slick-arrow-triangle {
    .slick-btn {
      color: #fff;
      width: 85px;
      height: 108px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 0;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      border: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
      z-index: 2;
      outline: none;
      text-align: center;
      line-height: 123px;

      i {
        position: relative;
        color: #fff;
        font-size: 30px;
      }

      &.slick-prev {
        border-bottom-right-radius: 108px;
        border-top-right-radius: 108px;
        left: 0;

        i {
          left: 11px;
        }
      }

      &.slick-next {
        border-bottom-left-radius: 108px;
        border-top-left-radius: 108px;
        right: 0;

        i {
          right: 11px;
        }
      }

      @media #{$md-layout} {
        left: 0px;
      }

      @media #{$lg-layout} {
        left: 0px;
      }

      @media #{$sm-layout} {
        left: 0px;
      }

      &:hover {
        background-color: #ffffff;
        color: #222222;

        i {
          color: #222222;
          transition: 0.4s ease-in-out;
        }
      }

      &.slick-next {
        left: auto;
        right: 0;

        @media #{$lg-layout} {
          left: auto;
          right: 0px;
        }

        @media #{$md-layout} {
          left: auto;
          right: 0px;
        }

        @media #{$sm-layout} {
          left: auto;
          right: 0px;
        }
      }
    }

    &.slick-arrow-trianglestyle-2 {
      .slick-btn {
        color: #fff;
        width: 55px;
        height: 100px;
        background: hsla(0, 0%, 100%, .5);
        border-radius: 0;

        &.slick-prev {
          border-bottom-right-radius: 108px;
          border-top-right-radius: 108px;
          left: 0;

          i {
            left: -2px;
          }
        }

        &.slick-next {
          border-bottom-left-radius: 108px;
          border-top-left-radius: 108px;
          right: 0;

          i {
            right: -2px;
          }
        }

        &:hover {
          background-color: #ffffff;
          color: #222222;

          i {
            color: #222222;
            transition: 0.4s ease-in-out;
          }
        }
      }
    }
  }

}


.slick-arrow-hover {
  &:hover {
    .slick-arrow-center,
    .slick-arrow-triangle,
    .slick-arrow-rounded {
      .slick-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}


// Slick Dots Transparent Position Center

.slick-dots-bottom {
  position: relative;

  &.bottom-30 {
    .slick-dots {
      bottom: 30px;
    }
  }

  .slick-dots {
    position: absolute;
    z-index: 2;
    bottom: -60px;

    li {
      width: auto;
      height: auto;
      margin: 0 9px;
      padding: 0;

      button {
        width: 10px;
        height: 10px;
        padding: 0;
        cursor: pointer;
        border: 0;
        outline: none;
        background: #d8d8d8;
        border-radius: 100%;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background: #222222;
        }
      }
    }
  }
}

.number-dot {
  .slick-dots {
    position: absolute;
    z-index: 2;
    bottom: 30px;
    // Responsive
    @media #{$sm-layout}{
      bottom: 15px;
    }

    li {
      margin: 0 10px !important;

      button {
        font-size: 20px !important;
        line-height: 20px !important;
        display: flex !important;
        flex-direction: row-reverse !important;
        color: var(--clr-white) !important;

        &::before {
          display: none;
        }

        &::after {
          content: "0";
        }
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: -5px;
        width: 0;
        height: 2px;
        background-color: var(--clr-white);
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
      }

      &.slick-active, &:hover {
        &::before {
          opacity: 1;
          visibility: visible;
          width: 100%;
        }
      }
    }
  }
}


.slick-dot-vertical-center {
  position: relative;

  .slick-dots {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 95px;
    width: auto;
    bottom: auto !important;

    @media #{$sm-layout} {
      right: 30px;
    }
    @media #{$md-layout} {
      right: 30px;
    }

    li {
      display: block;
      margin: 12px 0;

      button {
        font-size: 0;
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: hsla(0, 0%, 100%, .2);
        border-radius: 100%;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background: #ffffff;
        }
      }
    }
  }

  &.button-gray {
    .slick-dots {
      li {
        button {
          background: #d8d8d8;
        }

        &.slick-active {
          button {
            background: #222222;
          }
        }
      }
    }
  }

}

.carousel-slider {
  .thumb {
    img {
      width: 100%;
    }
  }

  .content {
    padding: 0 30px 0 0;

    .spacing {
      height: 28px;
    }

    h5 {
      // font-size: 18px;
      // margin-bottom: 14px;
      font-weight: 600;
      font-family: var(--ff-body);
      line-height: 1.666rem;
    }

    p {
      line-height: 1.58;
      font-size: 14px;
      font-weight: 500;
      font-family: var(--ff-body);
      color: var(--clr-body);
      margin: 0;
    }
  }
}
