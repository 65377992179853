

/* svg {
  filter: grayscale(1);
} */


body {
background: #000;
}

body, html {
  width: 100vw;
  overflow-x: hidden;
}

body::before, body::after {
	position: fixed;
	inset: 0;
	z-index: 2;
  top: 0;
  left: 0;
	--grad:
		radial-gradient(at 25%,
				#fff, #{rgba(#fff, 1)} 50%, transparent 70%);
	--mask: var(--grad), var(--grad), var(--grad);
	-webkit-mask: var(--mask);
	-webkit-mask-composite: source-in;
					mask: var(--mask);
					mask-composite: intersect;
	mix-blend-mode: lighten;
  /* opacity: 0.2; */
  filter: contrast(4) invert(1);
	/* content: '' */
}

body::before {
	/* background: #000; */
	filter: url(#f)
}

body::after {
		background: linear-gradient(90deg, #d8d8d8, #f0f0f0 20%, #eaeaea);
}


.logo-container {
  position: fixed;
  top: -0.5rem;
  left: 0;
  width: 120px;
  margin-left: calc(50vw - 60px);
  /* position: relative; */
  z-index: 8;
}

.logo-container img {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 8;
}

.logo-container-bg {
  background: #fff;
  /* background: #f00; */
  box-shadow: 0px 0px 24px 0px #0001;
  width: 420px;
  height: 80px;
  position: fixed;
  z-index: 6;
  left: calc(50vw - 210px);
  border-radius: 100%;
  top: -60px;
  transform: scaleY(2);

}

.bg-dot-img {
  width: 100vw;
  z-index: 1;
  position: fixed;
  height: auto;
  /* filter: contrast(0.2) invert(1); */
  opacity: 1;

}

.bg-dot-img img {
  width: 100%;
  transform: scale(2) translateY(-25%);
  height: auto;
  filter: blur(6px) saturate(5) contrast(20) invert(1);
  animation: blurin 36s infinite alternate-reverse;
}

@media only screen and (max-width: 768px) {
  .bg-dot-img {
    transform: rotate(90deg);
  }
}

@keyframes blurin {
  0% {
    transform: scale(1) translateY(-8.75%);
    opacity: 0.01;
    filter: blur(4px)  saturate(5) contrast(20) invert(1) grayscale(0.8) hue-rotate(30deg);
  }

  100% {
    opacity: 1;
    transform: scale(1.5)  translateY(-8.75%);
    filter: blur(16px)  saturate(5) contrast(2) invert(1) grayscale(0.8) hue-rotate(60deg);
  }
}


.modal-navigation-container {
  display: flex;
  justify-content: center;
  color: #000;
  position: fixed;
  left: 0rem;
  top: 2rem;
  z-index: 9;
  padding: 0.3333rem 0;
}

.modal-navigation-container p:last-of-type {
  border-right: none;
}

.modal-navigation-container p {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.modal-navigation-container {

}

@media only screen and (max-width: 992px) {
  .bg-dot-img {
    width: 100%
  }

  .bg-dot-img.mirror-img {
    display: none;
  }
}

@media only screen and (max-width: 520px) {

  .modal-navigation-container p {
    padding: 2px 0.5rem;
  }
}

@media only screen and (max-width: 340px) {
  .modal-navigation-container p {
    padding: 2px 0.25rem;

  }
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  transition: 0.2s;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  /* border: 1px solid #000; */
}


::-webkit-scrollbar:hover {
  width: 10px;
}

.slide-fade-in {
  animation: slideFadeTop 0.5s ease-out 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

.blur-panel {
  backdrop-filter: blur(8px) contrast(1.2);
}

.blur-panel-max {
  backdrop-filter: blur(20px) contrast(1.2) saturate(2);
}

.blur-panel-max-explosion {
  backdrop-filter: blur(20px) contrast(2) saturate(10);
}

.blur-panel-max-explosion-grayscale {
  backdrop-filter: blur(20px) contrast(2) saturate(10);
}

.slide-fade-in:nth-of-type(1) {
  animation-delay: 0.1333s;
}

.slide-fade-in:nth-of-type(2) {
  animation-delay: 0.1777s;
}

.slide-fade-in:nth-of-type(3) {
  animation-delay: 0.2369s;
}

.slide-fade-in:nth-of-type(4) {
  animation-delay: 0.3157s;
}

.slide-fade-in:nth-of-type(5) {
  animation-delay: 0.4369s;
}



.slide-fade-left:nth-of-type(1) {
  animation-delay: 0.1333s;
}

.slide-fade-left:nth-of-type(2) {
  animation-delay: 0.1777s;
}

.slide-fade-left:nth-of-type(3) {
  animation-delay: 0.2369s;
}

.slide-fade-left:nth-of-type(4) {
  animation-delay: 0.3157s;
}

.slide-fade-left:nth-of-type(4) {
  animation-delay: 0.4369s;
}



@keyframes slideFadeTop {
  0% {
    background: #fff;
    /* border: 1px solid #777; */
    color: #777;
    opacity: 0;
    transform: translateY(-5px);
  }
  66% {
    /* border: 1px solid #fff; */
    background: #1434A4;
    color: #fff;
  }
  100% {
    color: #fff;
    /* background:#0000; */
    /* border: 1px solid transparent; */
    transform: translateY(0px);
    opacity: 1;
  }
}

.slide-fade-left {
  animation: slideFadeLeft 0.5s ease-out 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

.long-delay {
  animation-delay: 0.561s;
}
@keyframes slideFadeLeft {
  0% {
    background: #fff;
    /* border: 1px solid #777; */
    color: #777;
    /* opacity: 0; */
    transform: translateX(-5px);
  }
  66% {
    background: #fff;
    /* border: 1px solid #fff; */
    color: #777;
  }
  100% {
    color: #fff;
    background:#000;
    border: 1px solid #fff;
    /* border: 1px solid transparent; */
    transform: translateX(0px);
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s linear 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100
  }
}

.slide-left {
  animation: slideLeft 1s ease-out 1;
}

@keyframes slideLeft {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}

.slide-right {
  animation: slideRight 1s ease-out 1;
}

@keyframes slideRight {
  0% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
}

.slide-bottom {
  animation: slideBottom 1s ease-out 1;
}

@keyframes slideBottom {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}

.slide-top {
  animation: slideTop 0.2s ease-out 1;
}

@keyframes slideTop {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}


/* SVG spinner icon animation */
.spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}
.spinner .path {
  stroke: #cccccc;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.mission-post-container p {
  margin: 2rem 0;
}