.katalog-container .post-card-container:nth-of-type(1) .post-card {
  margin-top: 2px;
}

.post-card {
  width: calc(100%);
  /* margin-left: 1rem; */
  display: flex;
  justify-content: space-around;
  position: relative;
  z-index: 4;
  /* padding: 0.5rem 0; */
  border-bottom: 1px solid #fff3;
  /* margin: 0.25rem 0; */
}

.post-card-inner {
  transition: color 2s;
  padding: 0rem 0rem;
  margin:0;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.postcard-img-container-desktop {
  width: calc(120px);
  height: calc(120px);

    cursor: pointer;

}


.post-card-right {
  text-align: right;
}

.postcard-flex-container {
  display: flex;
  justify-content: flex-end;
}

.post-card-inner-content {
  width: calc(100%);
  padding-left: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.post-card-container {
      text-decoration: none !important;
      color:inherit;
}

.post-card-container .post-card  h2 {
  padding: 0 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0rem;
  margin: 0rem 0 0 0;
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-end;
  font-family: "Index - Katalog";
  font-weight: 400;
  font-size: 1.25rem;
  color: #fff;

  /* padding-bottom: 7px; */
  text-decoration-style: none !important;
}

.post-card-container .post-card  h2 span {
  background :#666666;
}

.post-card .post-card-excerpt {
  margin: 0;
  padding: 0.5rem 0rem;
  padding-bottom: 0;
  font-size: 1rem;
  font-family: "Index - Katalog";
  font-weight: 300;
  line-height: 1.25rem;
  border: none;
  color: #707070;
  /* height: 100%; */
  padding-top: 0.25rem;
}

.post-card .post-card-cta {
  margin: 0;
  padding: 0.5rem 0rem;
  font-size: 1rem;
  font-family: "Index - Katalog";
  font-weight: 300;
  line-height: 1.25rem;
  border: none;
  color: #707070;
  padding-top: 0;
  padding-bottom: 0.5rem;
  text-decoration: underline;
  /* height: 100%; */
  padding-top: 0.25rem;
}

.tag-flex-container {
  /* background: #666; */
  display: flex;
  margin: 0.25rem 0;
}

.post-card-tag-item {
  display: inline-block;
  background: #666;
  color: #fff;
  font-family: "Index - Katalog";
  margin-right: 0.25rem;
}

.post-card h3.postcard-date {
  margin: 0;
  padding: 0.125rem 0.25rem;
  font-size: 0.66rem;
  padding-bottom: 1.25rem;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  /* display: none; */
  position: absolute;
  /* top: -12px; */
  left: 0px;
  /* border: 1px solid #fff; */
  /* bottom: 40px; */
  bottom: -12px;
  background: #000;
  text-align: left;
  /* transform: rotate(90deg); */
  color: #fff;

}



.postcard-img-container-desktop img {
  width: 100%;
  height: auto;
  max-height: 120px;
}

.postcard-img-container-mobile {
  display: none;
}
