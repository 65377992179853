.post-player-container {
  position: fixed;
  bottom: initial;
  top: calc(100% - 26px);
  width: 100%;
  z-index: 9999;
  transition: 0.5s;
  /* transform: translateY(100%); */
}

.post-player-container.post-player-active {
  /* transform: translateY(0%); */
  bottom: 0;
  top: initial;
}

.hide-player-btn:first-of-type {
  margin-right: 1rem;
}

.hide-player-btn {
  font-family: "Index - Katalog";
  color: #fff;
  background: #000;
  display: inline-block;
  float: right;
  margin: 0rem 0.5rem;
  padding: 0.25rem 0.5rem;
}
