.post-container {
  /* border: 1px solid #000; */
  /* width: 780px; */
  margin: auto;
  /* padding-top: 1rem; */
  /* background: #fff8; */
  /* box-shadow: 0px 0px 240px 120px #fff; */
  /* max-height: calc(100vh - 162px + 1rem); */
  padding: 0.5rem;
}

.modal-container .post-container {
  max-height: calc(100vh - 202px + 1rem);
}

.post-title {
  /* margin: 0rem 0; */
  /* font-family: "Index - Katalog"; */
  /* font-weight: 100; */
  /* font-size: 1rem; */
  /* padding: 0rem 0rem; */
  /* padding-bottom: 0; */
  /* color: #666; */
  /* margin-bottom: 0.5rem; */
  /* border-bottom: 1px solid #e0e0e0; */
  /* text-align: center; */
}

hr {
  margin: 1rem 0;
}

.post-subtitle {
  /* margin: 0rem 0;
  font-family: "Index - Katalog";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  padding: 0rem 0rem;
  padding-top: 0;
  color: #666;
  margin-top: 0.5rem; */
    /* border-bottom: 1px solid #e0e0e0; */
  /* text-align: center; */
}

.post-paragraph {
  /* margin: 1rem 0;
  font-family: "Helvetica Neue";
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #666; */
  /* text-align: center; */
}

.post-inner-container {
  /* margin: 0rem 0;
  font-family: "Index - Katalog";
  padding-bottom: 5rem;
  line-height: 1.5rem;
  color: #666; */
}

.post-inner-container p {
  margin: 0.5rem 0;
}

.post-single-inner-container-header {
  /* border: 1px solid #f00; */
  display: flex;
  /* border: 1px solid #f00; */
  align-items: center;
}

.post-single-inner-content-header {
  width: calc(100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #000; */
  justify-content: center;
  /* border: 1px so */
}

.post-single-image-title {
  /* width: 180px; */
  position: relative;
}

.post-single-image-title img {
  width: 100%;
  height: auto;
}

.post-mobile-title {
  display: none;
}

.wp-block-heading {
  background: #666;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  font-size: 1rem;
  margin: 0.5rem 0;
  padding: 0;
  line-height: 1rem;
}

.go-back-link {
  margin: 1rem 0.75rem;
  font-family: 'Index - Katalog';
  color: #666;
}

@media only screen and (max-width: 768px) {

  .post-container {
    /* margin-top: 0rem; */
    padding: 0.75rem;
    border: 1px solid #666666;
    margin: 1rem 0.75rem;
    border-top: 14px solid #666;
  }

   .post-title {
    margin-bottom: 0.5rem;
  }
/*
  .post-subtitle {
    background: #000;
    margin: 0;
    color: #fff;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .post-mobile-title {
    display: block;
    position: absolute;
    bottom: 4px;
    z-index: 9;
    font-family: "Index - Katalog";
    background: #fff;
    font-size: 1.5rem;
  }

  .post-single-inner-container-header {
    display: block;
  }

  .post-single-image-title {
    width: 100%;
  }

  .post-single-inner-content-header {
    width: 100%;
    transform: translateY(-4px);
  }

  .post-title {
    font-size: 1.5rem;
    padding: 0 0.5rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  .post-container {
    padding: 0;
  } */

/* .post-subtitle {
    padding: 0 0.5rem;
    margin-bottom: 0;
  } */

  /* .post-inner-container {
    padding: 0 1rem;
  } */
}

@media only screen and (max-width: 520px) {


}
