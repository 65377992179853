.releases-container {

  width: 480px;
  /* border: 1px solid #000; */
  margin: auto;
  padding-top: 120px;
  /* padding:  */
  /* display: flex;
  justify-content: space-between */
}

.releases-page-title {
  margin: 0.5rem 5px;
  margin-bottom: 0rem;
  background: #707070;
  font-size: 1rem;
  display: inline-block;
  color: #fff;
  font-family: 'Index - Katalog';
  font-weight: 400;
}

.inner-block-background-wrap {
  background: #707070;
}

.releases-bg {
  position: absolute;
  /* z-index: -1; */
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.4;
  /* opacity: 0.2; */
  height: 100%;
}

.scrollable-modal .release-flex-container {
  overflow-y: scroll;
  max-height: 371px;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 768px) {

  .scrollable-modal .release-flex-container {
    overflow-y: scroll;
    max-height: calc(100vh - 180px);
  }
}

.release-flex-container.single-page-container {
  max-width: 992px;
  align-items: center;
  height: 100vh;
  /* width: 996px; */
  margin: auto;
  align-items: center;
  /* padding: 2rem; */
  /* border: 1px solid #f00; */
}

h1.releases-title {
  font-family: "Helvetica Neue";
  text-align: center;
  font-weight: 400;
}

.toggle-about-btn.toggle-releases-btn {
  left: calc(100vw - 240px) !important;
}

.release-flex-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 4px;
  margin: 0 4px;
}

@media only screen and (min-width: 768px) {
  .release-album-artist {
    margin-left: 0 !important;
  }

  .release-album-title {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 768px) {



  .release-outer-container {
    flex-basis: calc(50% - 5px);
    /* flex-basis: calc(33.33333%); */
  }

  .single-page-releases-container {
    border: 1px solid #666;
    margin: 0rem 0.5rem;
  }

  .release-image-container {
    padding: 0.5rem 0.25rem;
  }
}

@media only screen and (max-width: 420px) {
  .release-flex-container {
    display: block;
  }
}

/* .release-outer-container:nth-of-type(3n) {
  padding-right: 0.25rem;
}

.release-outer-container:nth-of-type(3n - 2) {
  padding-right: 0.25rem;
} */

.release-image-container {
  /* padding: 2px 2px; */
  width: 100%;
  /* padding: 0.25rem 0.25rem; */
  padding-bottom: 0;
  margin-bottom: 0;
  box-sizing: border-box;
}

.release-outer-container {
  flex-basis: calc(33.33333% - 5px);
  /* flex-basis: calc(33.33333%); */
}

.release-album-artist {
  margin-top: 0;
  /* background: #666666; */
  color: #fff;
  margin: 0 0.25rem;
  display: inline-block;
  font-size: 1rem;
  font-family: "Index - Katalog";
  font-weight: 400;
  margin-bottom: 0.125rem;
}

.release-album-title {
  color: #666666;
  margin: 0 0.25rem;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-family: "Index - Katalog";
  font-weight: 400;
}

.release-modal-container {
  left: calc(100vw - 580px);
  bottom: 80px;
  top: initial;

}

.release-container {
  width: 33.3333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid #f0f; */
  text-align: center;
  z-index:9;
  display: none;
}

.release-container h3 {
  font-family: "Helvetica Neue";
  font-size: 1rem;
  font-weight: 300;
  /* min-height: 4rem; */
  /* background: #000; */
  padding: 4px 2px;
  margin: 0 14px;
  /* color: #fff; */
  display: inline-block;
  height: 3.157rem;
  /* outline: 1px solid #0F0; */
  display: flex;
  align-items: flex-start;

}

.release-img-container {
  width: 120px;
  margin: auto;
}

.release-img-container img {
  /* border-radius: 100%; */
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 668px) {

  .releases-container {
    width: 320px;
  }

  .release-container {
    width: 50%;

  }
  .release-img-container {
    width: 105px;
  }

  .release-container h3 {
    font-family: "Gruppo";
    font-size: 1.25rem;
    margin-top: 0.5rem;
    min-height: 4rem;
  }

}


.releases-content-container {
/* position: relative; */
}

.releases-content span {
  position: relative;
  z-index: 9;
  color: #000;
}

.releases-container {
  position: fixed;
  z-index: 6;
  width: calc(500px);
  border: 1px solid #fff;
  left: calc(50px);
  top: -240px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem 0rem;
  padding: 0.5rem 0;
  box-sizing: border-box;
}


 .releases-container-active {
  box-shadow: 0px 0rem 2rem 4px #fff7, 0px 0px 24px 24px #f0f0f0, 0px 24px 24px 0px #f0f0f0, 0px 0px 24px 12px #fff;;
  background: #fff;
  top: 124px;
  z-index: 99;
  padding: 1rem 0.5rem;
  animation: fadein 0.175s ease-in-out 1;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
80% {

  transform: translateY(0px);
}

  100% {
    opacity: 1;
  }
}

.releases-container h2 {
  margin: 0;
  font-family: 'Gruppo';
  padding: 0.25rem 0.5rem;
  color: #000;
  /* border: 1px solid #cacaca; */
  margin-left: 0.25rem;
  padding-right: 1rem;
  margin-bottom: 4px;
  padding-top: 6px;
  border-top-left-radius: 0.5rem;
  /* border: 1px solid #000; */
  min-height: 28px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
    font-family: "Index - Katalog";
}

.releases-container p {
  color: #fff;
  font-family: "Gruppo";
  padding: 5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
  z-index: 2;
  /* position: relative; */
  /* font-weight: 700; */
  /* margin-top: 8px; */
}

.releases-container h4:hover {
  background: #000;
  color: #fff;
}


.releases-container-header {
  position: absolute;
  top: -29px;
  font-size: 1.5rem;
  color: #fff;
  text-align: right;
  background: #000;
  width: 100%;
  left: 0;
  padding-right: 14px;
  box-sizing: border-box;
  font-family: "Gruppo";
  font-weight: 700;
}

.toggle-releases-btn {
  cursor: pointer;
  z-index: 9;
  position: fixed;
  background: #fffa;
  font-family: "Gruppo";
  width: 80px;
  font-weight: 900;
  font-size: 1.125rem;
  padding: 4px 2px;
  /* color: #000 !important; */
  left: calc(44px);
  margin: 0;
  top: 100px;
  border: none;
  box-shadow: 0px 0px 24px 0px #fff8;
  border-radius: 4px;
}

.releases-container-header p {
  margin: 0rem 0;
  padding: 0.125rem 0;
  padding-bottom: 0.175rem;
  border-left: 1px solid #fff;
  width: 24px;
  float: right;
  cursor: pointer;
}
