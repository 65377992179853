.archive-container {
  width: calc(100% - 80px);
  background: #fff;
  margin: 0 40px;
  border: 2px solid #000;
  height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
}

.archive-inner-container {
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}


ul.options-menu-archive {
  position: absolute;
  list-style: none;
  margin-top: 14px;
}

.options-menu-archive li {
  display: inline;
  margin-right: 18px;
}
