.about-content-container {
/* position: relative; */
padding: 0.5rem;
}

.about-title {
  font-family: 'Index - Katalog';
  font-size: 1rem;
  background: #707070;
  display: inline-block;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.about-copy {
  font-family: 'Index - Katalog';
  font-size: 1rem;
  color: #707070;
}

.about-link {
  font-family: 'Index - Katalog';
  font-size: 1rem;
  color: #707070;
  text-decoration-style: none;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.about-content span {
  position: relative;
  z-index: 9;
  color: #707070;
}

.about-container {
  /* transition: 0.2s; */
  /* position: fixed; */
  /* z-index: 6;
  width: calc(500px);
  border: 1px solid #fff;
  left: calc(50px);
  top: -240px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem 0rem;
  padding: 0.5rem 0;
  box-sizing: border-box; */
}

.about-bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.7;
  /* opacity: 0.2; */
  height: 100%;
}

 .about-container-active {

  /* box-shadow: 0px 0rem 2rem 4px #fff7, 0px 0px 24px 24px #f0f0f0, 0px 24px 24px 0px #f0f0f0, 0px 0px 24px 12px #fff;; */
  background: #fff;
  /* top: 124px; */
  z-index: 99;
  /* padding: 1rem 0.5rem; */
  animation: fadein 0.175s ease-in-out 1;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
80% {

  transform: translateY(0px);
}

  100% {
    opacity: 1;
  }
}

.about-container h2 {
  margin: 0;
  /* font-family: 'Gruppo'; */
  padding: 0.25rem 0.5rem;
  color: #000;
  /* border: 1px solid #cacaca; */
  margin-left: 0.25rem;
  padding-right: 1rem;
  margin-bottom: 4px;
  padding-top: 6px;
  border-top-left-radius: 0.5rem;
  /* border: 1px solid #000; */
  min-height: 28px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
    font-family: "Index - Katalog";
}

.about-container p {
  color: #fff;
  font-family: "Index - Katalog";
  padding: 5rem 1rem;
  font-size: 1rem;
  line-height: 1rem;
  z-index: 2;
  /* position: relative; */
  /* font-weight: 700; */
  /* margin-top: 8px; */
}

.about-container h4:hover {
  background: #000;
  color: #fff;
}


.about-container-header {
  position: absolute;
  top: -29px;
  font-size: 1.5rem;
  color: #fff;
  text-align: right;
  background: #000;
  width: 100%;
  left: 0;
  padding-right: 14px;
  box-sizing: border-box;
  font-family: "Index - Katalog";
  font-weight: 700;
}

.toggle-about-btn {
  cursor: pointer;
  z-index: 9;
  position: fixed;
  background: #fffa;
  font-family: "Index - Katalog";
  width: 80px;
  font-weight: 900;
  font-size: 1.125rem;
  padding: 4px 2px;
  /* color: #000 !important; */
  left: calc(44px);
  margin: 0;
  top: 100px;
  border: none;
  box-shadow: 0px 0px 24px 0px #fff8;
  border-radius: 4px;
}

.about-container-header p {
  margin: 0rem 0;
  padding: 0.125rem 0;
  padding-bottom: 0.175rem;
  border-left: 1px solid #fff;
  width: 24px;
  float: right;
  cursor: pointer;
}

.about-content {
  font-family: "Index - Katalog";
  padding: 5rem 1rem;
  font-size: 1rem;
  line-height: 1rem;
}

@media only screen and (max-width: 768px) {
  .about-content-container {
    height: calc(100vh - 96px);
    display: flex;
    align-items: center;
    padding: 0 2rem;
  }

  .about-content {
    font-size: 1rem;
    line-height: 1.25rem;
    color: #707070;
  }
}
