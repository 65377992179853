.filter-container {
  border: 1px solid #000;
  max-width: 640px;
  margin: auto;
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.filter-container.floating-filter-container {
  position: fixed;
  width: 640px;
  left: calc(50% - 320px);
  max-width: 100%;
  background: #fff;
}

.filter-container ul {
  list-style-type: none;
}

.filter-container ul li {
  display: inline-block;
  /* border: 1px solid #000; */
  margin: 0 14px;
}
