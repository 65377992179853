.post-slider-container {
width: 100%;
  margin: auto;
  border: 1px solid #cacaff;
  max-height: calc(100vh - 100px);
  overflow-y: hidden;
}


.post-slide-container {
  border: 1px solid #ffcaca;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-slide-inner-container {
  width: 90%;
  border: 1px solid #caffca;
  max-width: 640px;

}
