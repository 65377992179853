.post-list-container {
  max-width: 640px;
  margin: auto;
  margin-top: 24px;
  padding-bottom: 14px;
  border: 1px solid #cacaca;
  /* max-height: calc(100vh - 220px);
  overflow-y: scroll; */
}

.post-single-container {
  width: 100%;
  margin-bottom: 21px;
}

.post-list-container h1 {
  background: #000;
  color: #fff;
  width: 100%;
  font-size: 1.333rem;
  padding-left: 14px;
  box-sizing: border-box;
}

.post-list-container h6 {
  box-sizing: border-box;
  padding-left: 14px;
}

.post-list-container h1, .post-list-container h6 {
  margin: 0;
}

.image-placeholder {
  border: 1px solid #000;
  background: #f1f1f1;
  width: calc(100% - 28px);
  /* height: 310px; */
  box-sizing: border-box;
  margin: 14px 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

}

.post-single-container p {
  padding: 0px 14px;
  margin: 0;
}

.image-placeholder img {
  width: 100%;
  height: auto;
}
