nav.navigation-menu {
  position: fixed;
  left: calc(50vw - 280px);
  top: 100px;
}

nav.navigation-menu ul {
  background: #333;
  border-radius: 4px;
    box-shadow: 0px 0px 24px 0px #fff8;

}

nav.navigation-menu ul li {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 999;
  /* position: fixed; */
  /* background: #fffa; */
  font-family: "Gruppo";
  width: 80px;
  font-weight: 900;
  font-size: 1rem;
  padding: 0px 2px;
  /* color: #000 !important; */
  /* left: calc(50vw - 44px); */
  margin: 0;
  /* top: 100px; */
  border: none;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  border-right: 1px solid #888;
}

.menu-icon img {
  width: 100%;
  height: auto;
}

.menu-icon {
  width: 48px;
  height: 48px;
  /* border: 1px solid #000; */
  position: fixed;
  /* margin: 0.5rem; */
  top: 16px;
  right: 24px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9;
  cursor: pointer;
}

.menu-icon-stripe {
  width: 66.66%;
  height: 3px;
  margin: 2px 0;
  background: #686868;
  border-radius: 2px;
  box-shadow: 0px 0px 4px 0px #f0f0f077;
  border: 1px solid #cacaca;

}

.mobile-menu-container {
  position: fixed;
  max-height: 25vh;
  z-index: 999;
  width: calc(240px);
  border: 1px solid #fff;
  left: initial;
  right: 12px;
  /* left: calc(50vw - 254px); */
  top: 36px;
  background: #000;
  /* box-shadow: 0px 0px 12px 8px #000 inset; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem 0rem;
  padding: 0.5rem 0;
  box-sizing: border-box;
}

/* .page-navigation-navigation * {
  cursor: pointer;
} */

.page-navigation-container a {
  cursor: pointer;
  text-decoration: none;
}

 .mobile-menu-container-active {
  /* box-shadow: 0px 0rem 2rem 4px #fff7, 0px 0px 24px 24px #f0f0f0, 0px 24px 24px 0px #f0f0f0;
  background: #000;
  top: 124px;
  z-index: 99;
  padding: 1rem 0.5rem; */
  animation: fadeside 0.175s ease-in-out 1;
}

@media only screen and (min-width: 768px) {
  .modal-navigation-container.page-navigation-container {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .modal-navigation-container:not(.page-navigation-container) {
    display: none;
  }
}

.modal-navigation-container.page-navigation-container {
  position: relative;
  margin-top: 0;
  top: 0.5rem;
  justify-content: flex-start;
  /* left: -0.5rem; */
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}

.modal-navigation-container.page-navigation-container p:first-of-type {
  /* margin-left: 0.5rem; */
}

.modal-navigation-container.page-navigation-container p {
    margin-left: 0rem;
    margin-right: 0.5rem;

}

@keyframes fadeside {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  /* 11% {
    opacity: 11%;
  }
  12% {
    opacity: 1;
  }
  13% {
    opacity: 0;
  } */
80% {

  transform: translateX(0px);
}

  100% {
    opacity: 1;
  }
}


.mobile-menu-container ul {
  text-align: center;
  width: 100%;
  margin: 0rem 0.5rem;
}

.mobile-menu-container li {
  list-style-type: none;
}

.mobile-menu-container ul li a {
  height: 22px;
  display: inline-block;
  margin: 0 0.25rem;
  font-family: "Gruppo";
  border: 1px dotted #fff;
  padding: 0 0.25rem;
  border-radius: 0.125rem;
  display: flex;
  padding-right: 1rem;
  padding-top: 4px;
  cursor: pointer;
  color: #fff;
  background: #000;
  border-top: 1px solid #fff;
  border-right: 1px solid #bbb;
  border-left: 1px dotted #999;
  border-bottom: 1px dotted #666;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  /* margin-top: 8px; */
}

.mobile-menu-container h4:hover {
  background: #000;
  color: #fff;
}


.mobile-menu-container-header {
  position: absolute;
  top: -29px;
  font-size: 1.5rem;
  color: #fff;
  text-align: right;
  background: #000;
  width: 100%;
  left: 0;
  padding-right: 14px;
  box-sizing: border-box;
  font-family: "Gruppo";
  font-weight: 700;
}

.mobile-menu-container-header p {
  margin: 0rem 0;
  padding: 0.125rem 0;
  padding-bottom: 0.175rem;
  border-left: 1px solid #fff;
  width: 24px;
  /* border: 1px solid #fff; */
  transform: translateX(14px);
  padding-right: 14px;
  float: right;
  cursor: pointer;
}

.bottom-menu {
  background: #fff;
  position: fixed;
  z-index: 2;
  bottom: 20px;
  color: #fff;
  left: calc(50% - 180px);
  display: flex;
  justify-content: space-between;
  width: 360px;
}

.bottom-menu .bottom-menu-icon {
  margin: 0 4px;
  background: #000;
  height: 56px;
  width: 56px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-menu-icon p {
  background: #fff;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  min-width: 56px;
  font-family: "Gruppo";
  font-weight: 700;
  padding: 2px 0;
}

@media only screen and (min-width: 768px) {
  .mobile-header-logo-container {
    display: none;
  }

  .menu-icon {
    display: none;
  }
}

@media only screen and (max-width: 768px) {

  .mobile-header-logo-container {
    /* display: block; */
    width: 100%;
    max-width: 180px;
    margin: auto;
    /* background: #f00; */
    display: flex;
    justify-content: center;
    height: 3rem;
    z-index: 999999;
    position: relative;
  }

  .mobile-header-logo {
    width: 120px;
    position: fixed;
    top: -10px;
    /* background: #f00; */
  }

  .mobile-header-logo img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .menu-icon {
    top: 2px;
    display: block;
  }
}
