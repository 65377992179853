

.tags-container {
  z-index: 6;
  gap: 0.5rem 0rem;
  box-sizing: border-box;
}

.tags-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.tags-container::after {
  content: "";
  flex: flex-start;
}

 .tags-container-active {
  box-shadow: 0px 0rem 2rem 4px #fff7, 0px 0px 24px 24px #f0f0f0, 0px 24px 24px 0px #f0f0f0, 0px 0px 24px 12px #fff;;
  background: #fff;
  top: 124px;
  z-index: 99;
  padding: 0.25rem 0.25rem;
  animation: fadein 0.175s ease-in-out 1;
}

h6.tags-section-header {
  margin: 0.333rem 0.5rem;
  /* margin-top: 1rem; */
  font-family: "Index - Katalog"
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
80% {

  transform: translateY(0px);
}

  100% {
    opacity: 1;
  }
}

.tags-container h2 {
  margin: 0;
  font-family: 'Gruppo';
  padding: 0.25rem 0.5rem;
  color: #000;
  /* border: 1px solid #cacaca; */
  margin-left: 0.25rem;
  padding-right: 1rem;
  margin-bottom: 4px;
  padding-top: 6px;
  border-top-left-radius: 0.5rem;
  /* border: 1px solid #000; */
  min-height: 28px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
    font-family: "Index - Katalog";
}

.tags-container h4 {
  /* height: 22px; */
  display: inline-block;
  margin: 0.25rem 0.25rem;
  font-family: "Index - Katalog";
  /* border: 1px dotted #fff; */
  background: #666;
  /* padding: 0 0.25rem; */
  border-radius: 0.125rem;
  display: flex;
  /* padding-right: 1rem; */
  /* padding-top: 4px; */
  line-height: 0.75rem;
  cursor: pointer;
  color: #000;
  font-weight: 400;
  color: #fff;
  /* background: #fff; */
  border-top: 1px solid #fff;
  font-size: 1rem;
  /* border-right: 1px solid #bbb;
  border-left: 1px dotted #999;
  border-bottom: 1px dotted #666; */
  /* font-weight: 700; */
  /* margin-top: 8px; */
}

.tags-container h4:hover {
  background: #000;
  color: #fff;
}


.tags-container-header {
  position: absolute;
  top: -29px;
  font-size: 1.5rem;
  color: #fff;
  text-align: right;
  background: #000;
  width: 100%;
  left: 0;
  padding-right: 14px;
  box-sizing: border-box;
  font-family: "Gruppo";
  font-weight: 700;
}

.tags-container-header p {
  margin: 0rem 0;
  padding: 0.125rem 0;
  padding-bottom: 0.175rem;
  border-left: 1px solid #fff;
  width: 24px;
  float: right;
  cursor: pointer;
}

.toggle-tags-btn {
  cursor: pointer;
  z-index: 9;
  position: fixed;
  background: #fffa;
  font-family: "Index - Katalog";
  /* width: 80px; */
  font-weight: 400;
  font-size: 1rem;
  /* font-size: 1rem; */
  width: 320px;
  padding: 2px 2px;
  /* color: #000 !important; */
  left: calc(50vw - 160px);
  margin: 0;
  top: 132px;
  border: none;
  /* border: 1px solid #cacaca; */
  box-shadow: 0px 0px 4px 4px #f7f9fc;
  border-radius: 4px;
  /* background: #333; */
  /* color: #fff; */
  padding: 4px;
}


@media only screen and (max-width: 668px){
  .tags-container {
    /* width: 320px; */
    left: calc(50vw - 160px);
    /* overflow: auto; */
  }

  .tags-container-active {
    /* padding-bottom: 5rem; */
  }
}
