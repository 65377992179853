@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Index - Katalog";
  src: local("Index - Katalog"), url(./assets/Katalog.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'OldStyle 1 HPLHS';
  src: local('OldStyle 1 HPLHS'), url('./assets/OldStyle1.woff') format('woff');
}

@font-face {
  font-family: 'Teko';
  src: local('Teko'), url('./assets/Teko-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Cinzel';
  src: local('Cinzel'), url('./assets/Cinzel-Regular.woff') format('woff');
}

@font-face {
  font-family: "Inter Tight";
  src: local('Inter Right'), url('./assets/InterTight-Light.woff') format('woff');
}

.hide-scrollbar
{
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}
