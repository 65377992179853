* {
  margin: 0;
  padding: 0;
}

#canvas {
  overflow: hidden;
  position: relative;
  z-index: -1;
  position: fixed;
}

.highlight-card-container {
  /* position: absolute;
  top: 84px; */
  margin-left: 1rem;
  margin-right: 1rem;
  z-index: 99;
  font-family: 'Index - Katalog';
  font-weight: 400;
  max-width: 480px;
  /* width: 100%; */
  width: calc(100% - 2rem);

}

@media only screen and (max-width: 768px) {
  .highlight-card-container {
    top: 64px;
    margin-left: 0.5rem;
    margin-right: 0.5;
    width: calc(100% - 1rem);
  }
}

.highlight-card {
  margin-bottom: 1rem;
}


.highlight-card-footer {
  background: #000;
  color: #fff;
  padding: 0.5rem;
  border: 1px solid #fff;
  border-top: none;
}

.home-placeholder {
  width: calc(100% - 80px);
  background: #fff;
  margin: 0 40px;
  border: 2px solid #000;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000;
  font-weight: 700;
  font-size: 5rem;
  text-transform: uppercase;
  font-family: "NT Wagner";
}



.home-circle {
  width: 100vw;
  height: 100vh;
  background: #000;
  /* border-radius: 100%; */
  filter: url('#wavy');
}

.home-circle-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-image: url('../../assets/dot-bg.png');
  /* opacity: 0.1; */
  background-size: 0.3%;
  /* border-radius: 100%; */
  filter: url('#wavy3');
  animation: bgsize 10s linear 1;
}

.home-heading {
  position: absolute;
  z-index: 2;
  color: #000;
  font-size: 15rem;
  font-family: monospace;
}

.home-column-flex-container {
  display: flex;
}

.home-column {
  max-height: 100vh;
  /* border: 1px solid; */
}

.column-25 {
  width: 33.333333%;
  display: none;
}

.column-75 {
  width: 100%;
  max-width: 480px;
  background: #f7f9fc44;
  box-shadow: 0px 0px 24px 24px #f7f9fc88;
  margin: 0 auto;
  padding: 0.75rem;
  margin-top: 120px;
  margin-bottom: 2rem;
  padding-bottom: 120px;
  border-bottom: 1px solid #cacaca;

  /* margin-left: calc(25% + 1vw); */
  /* border: 1px solid #f0f; */
  /* background: #f0f0f0; */
}
.shift {
  animation: shift 1s linear infinite alternate;
}

.tag-show-btn {
  width: 80px;
  height: 32px;
  /* background: #fff; */
  /* border: 1px solid #000; */
  position: fixed;
  margin-left: calc(50vw - 40px);
  bottom: 0rem;
  border-radius: 0.5rem;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.tag-show-btn p {
  margin: 0;
  padding: 0;
  font-family: "Index - Katalog";
  font-weight: 300;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin-top: 4px;
  color: #fff;
}

.tag-show-btn-bg {
  position: fixed;
  width: 80px;
  height: 80px;
  background: #444;
  bottom: -40px;
  transform: scaleX(0.75) rotate(45deg);
  left: calc(50vw - 40px);
  z-index: -1;
  border-radius: 1rem;
}


.rgb-bg {
  background-image: url('../../assets/rgb.png');
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  opacity: 0.15;
  z-index: -1;
  /* filter: url('#wavy2'); */
  transform: scale(2);
}

@keyframes shift {
  0% {
    filter: contrast(1);
  }
  100% {
    filter: contrast(3);
  }
}


#noise1 {
  width: 100px;
  height: 100px;
}

.bg-dot {
  display: none;
  width: 100px;
  height: 100px;
  background: radial-gradient(      circle at 50% 50%,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    ),
    url('../../assets/noise.svg');
    filter: contrast(170%) brightness(1000%);
}

.featured-area {
  max-height: 75vh;
  overflow-y: hidden;
}

.featured-image-container {
  width: 100%;
}

.featured-image-container img {
  width: 100%;
  height: auto;
}

.desktop-home {
  display: block;
}

.home-highlight-logo-container {
    /* width: 120px; */
    height: 100vh;
    right: 0;
    display: flex;
    align-items: center;
    transform: scale(0.5);
    filter: url('#wavy') blur(8px);
    opacity: 0.333;
    /* border: 1px solid #f00; */
    width: calc(100% - 518px);
    position: fixed;
    z-index: 2;
}

.home-highlight-logo-container img {
  width: 100%;
  height: auto;
}

.mobile-center-logo {
  width: 100%;
  max-width: 420px;
  height: 180px;
  margin: auto;
  position: relative;
  z-index: 9;
}


.mobile-center-logo img {
  width: 100%;
  height: auto;
}

.mobile-home-nav ul {
    list-style: none;
    padding-right: 15px;
}

.mobile-home-nav ul li {
  font-family: "Index - Katalog";
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-weight: 900;
  font-size: 1rem;
  text-align: center;
  z-index: 9;
  position: relative;
  line-height: 4rem;
  filter: urL('#wavy');
}

.mobile-home-nav ul li a {
  color: inherit;
  text-decoration: none;
  /* transform: translateX(-20px); */
}

.modal-navigation-container .modal-navigation-item {
  cursor: pointer;
}

/* .mobile-home-nav ul li:nth-of-type(odd) {
  transform: translateX(-20px);
}


.mobile-home-nav ul li:nth-of-type(even) {
  transform: translateX(20px);
} */

@media only screen and (min-width: 768px) {
  .mobile-home {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .desktop-home {
    /* display: none; */
  }

  .mobile-home {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-featured-post-card {
    width: 100%;
    position: relative;
    z-index: 9;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 1rem;
    /* border-top : 1px solid #000; */
    margin-top: 3rem;
    padding-top: 1.5rem;
  }

  .mobile-featured-post-content {
    padding-left: 1rem;
  }

  .mobile-featured-post-image-container {
    width: 50%;
        border-radius: 100%;
        position: relative;
        padding-right: 1rem;
  }

  .mobile-featured-post-title {
    display: flex;
    align-items: flex-end;
    font-family: "Index - Katalog";
  }

  .mobile-featured-post-image-container .thumbnail-halo-frame {
        box-shadow: 0px 0px 24px 24px #fff inset;
        position: absolute;
        width: 100%;
        height: 100%;
        top: -2px;
        left: -2px;
        /* right: 1px; */
        content: '&nbsp;';
        border-radius: 100%;
  }

  .mobile-featured-post-image-container img {
    width: 100%;
    height: auto;
    border-radius: 100%;
    position: relative;

  }
}

@keyframes bgsize {
  0% {
    width: 5%;
    height: 5%;
  }

  100% {
    height: 500%;
    width: 500%;
  }
}
