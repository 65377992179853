.scrollable-modal .katalog-container {
  /* border: 1px solid #f00; */
  width: 100%;
  max-height: 390px;
  overflow-y: scroll;
  /* padding: 1rem 0; */

}

.post-card-container:last-of-type{
  margin-bottom: 64px;
}

.active-tag-container {
  color: #fff;
  position: absolute;
  z-index: 9;
  /* border: 1px solid #fff; */
  top: -24px;
  background: #444;
  /* font-size: 1rem; */
  right: 128px;
  height: 18px;
  line-height: 18px;
  vertical-align: middle;
  font-weight: 700;
  padding: 0 1rem;
  font-family: "Gruppo";
  /* padding: 2px 4px; */
}

.katalog-page-title {
  font-family: 'Index - Katalog';
  font-weight: 400;
  margin-left: 0.5rem;
  font-size: 1rem;
  margin-bottom: 0.777rem;
  background: #666666;
  border-radius: none;
  color: #fff !important;
  border-radius: none;
  display: inline-block;
}

.page-katalog-container {
  /* background: #000; */
  min-height: calc(100vh - 48px);
  border: 1px solid #666;
  margin: 0.5rem 0.5rem;
  border-top: 14px solid #666;
}

.page-katalog-header {
  /* background: #000; */
  /* height: 2.5rem; */
  /* background: #666; */
  display: inline-block;
  justify-content: center;
  /* border-bottom: 1px solid #fff; */
}

.page-katalog-tags-btn {
  background: #666;
  color: #000;
  display: inline-block;
  /* height: 24px; */
  /* padding: 0 1rem; */
  font-weight: 400;
  color: #fff;
    font-family: "Index - Katalog";
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
}

.post-card-placeholder {
  border: 1px solid #666;
  height: 72px;
  width: calc(100% - 1rem);
  margin: 0.5rem 2rem;
}
