.modal-content-container {
/* position: relative; */
}

.modal-content span {
  position: relative;
  z-index: 9;
  color: #000;
}

.modal-title {
  display: inline-block;
  /* font-size: 1rem; */
  float: left;
  margin-top: 0.425rem;
  margin-left: 1rem;
  margin-right: 1rem;
  /* font-family: "Index - Katalog"; */
  font-weight: 400;
}

.modal-container {
  transition: 0.25s;
  transition-property: filter;
  transition-timing-function: ease-in-out;
  position: fixed;
  z-index: 6;
  width: calc(500px);
  /* border: 1px solid #fff; */
  left: calc(50px);
  top: -240px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem 0rem;
  padding: 0rem 0;
  box-sizing: border-box;
  /* transition: 0.05s; */
  /* max-height: 420px; */
}


.modal-content-container {

  /* display: inline-block; */

  /* overflow-y: auto; */
  overflow-y: scroll;
  /* max-height: calc(100vh - 120px); */
  width: 100%;
}

.modal-container.tags-modal-container {
  left: initial;
  right: calc(50vw - 582px);
  top: 136px;
  width: 420px;
  max-height: 420px;
}

.modal-container.about-modal-container {
  top: 162px;
  left: 162px;
  width: 480px;
  /* top: initial; */
}

.modal-container.releases-modal-container {
  left: calc(100vw - 780px);
  top: 124px;
  height: 420px;
  width: 720px;
}

@media only screen and (max-width: 768px) {

  /* .modal-container.releases-modal-container {
    left: calc(100vw - 720px - 3rem);
    top: 102px;
    height: 720px;
    width: 720px;
  } */
}

.modal-container.forum-modal-container {
  top: 102px;
}

.modal-container.katalog-modal-container {
  left: 3rem;
  top: calc(100vh - 520px);
  /* bottom: 40px; */
  width: 680px;
  max-height: 396px;
  padding: 0.25rem 0;
  /* background: #000; */
}

.modal-container.single-modal-container {
  width: 580px;
  left: calc(50% - 280px);
  top: 92px;
  max-height: calc(100% - 124px);
}

.modal-container.single-modal-container.artists-modal-container {
  width: 780px;
  right: 4rem;
  left: initial;
  top: 144px;
  max-height: calc(100% - 124px);
}

.modal-container.single-modal-container.artists-modal-container.videostream-modal-container {
    right: 10rem;
    top: 224px;
}

@media only screen and (max-width: 768px) {
  .modal-container.single-modal-container.artists-modal-container.videostream-modal-container {
      right: 0rem;
      top: calc(140px);
      width: calc(100% - 0rem);
      /* height: 320px; */
      margin: 0rem;
  }
}

.modal-container.katalog-modal-container.news-modal-container {
   top: 12rem;
   left: initial;
   top: 178px;
   right: 13.5rem;
}


.modal-container.single-modal-container.artists-modal-container.releases-modal-container {
  right: 1rem;
  top: 108px;
}


.modal-container.single-modal-container.mission-modal-container {
  width: 480px;
  left: 4rem;
  top: 8rem;
}

.modal-container.single-modal-container.embed-modal-container {
  width: 420px;
  left: initial;
  right: 148px;
  top: initial;
  bottom: 36px;
  max-height: calc(100% - 162px);
  border: 1px solid #666;
}

@media only screen and (max-width: 792px) {
  .modal-container.single-modal-container {
    width: 100%;
    left: 0;
    top: 128px;
  }
}

.modal-container.info-modal-container {
  width: 380px;
  left: calc(50% - 390px);
  top: 132px;
  max-height: calc(100% - 162px);
}

.modal-container.info-modal-container .wp-block-image {
  max-width: 380px;

}

.modal-container.info-modal-container .wp-block-image img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 792px) {
  .modal-container.info-modal-container {
    width: 100%;
    left: 0;
    top: 128px;
  }
}

@media only screen and (max-width: 520px) {
  .modal-container.katalog-modal-container, .modal-container.forum-modal-container, .modal-container.releases-modal-container, .modal-container.about-modal-container {
    width: calc(100% - 1rem);
    left: 0.5rem;
    top: 128px;
  }

  .modal-container.tags-modal-container {
    width: calc(100% - 4rem);
    left: 2rem;
  }
}

.modal-header-inner-container {
  /* display: inline-block; */
  display: flex;
  justify-content: flex-end;
  width: 100%;
  float: right;
}

.modal-bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.7;
  /* opacity: 0.2; */
  height: 100%;
}

.modal-option {
  position: absolute;
  top: -24px;
  /* border: 1px solid #707070; */
  left: 0;
  font-size: 1rem;
  margin-right: 0.5rem;
  margin-top: 0.33rem;
  color: #fff;
  background: #666;
  font-weight: 200;
  display: inline-block;
  line-height: 14px;
  vertical-align: middle;
  height: 14px;
  padding: 0 1rem;
  font-family: "Index - Katalog", sans-serif;
}

 .modal-container-active {
  /* box-shadow: 0px 0rem 2rem 4px #00f, 0px 0px 24px 24px #00f, 0px 24px 24px 0px #00f, 0px 0px 24px 12px #fff7;; */
  /* background: #000; */
  /* border: 1px solid #fff; */
  top: 124px;
  z-index: 99;
  padding: 0;
  animation: fadein 0.225s ease-in-out 1;
}

.modal-container-active.secondary-modal {
  box-shadow: none;
}

.modal-container-active.secondary-modal .modal-container-header {
  /* background: #fff; */
  /* color: #666666; */
  /* border: 1px solid #fff; */
  /* border-bottom: 1px solid #000; */
  /* width: calc(100% + 2px); */
  /* left: -1px; */
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
80% {

  transform: translateY(0px);
}

  100% {
    opacity: 1;
  }
}

/* .modal-container h2 {
  margin: 0;

  padding: 0.25rem 0.5rem;
  color: #000;

  margin-left: 0.25rem;
  padding-right: 1rem;
  margin-bottom: 4px;
  padding-top: 6px;
  border-top-left-radius: 0.5rem;

  min-height: 28px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
    font-family: "Index - Katalog";
} */

.modal-container p.close-button {
  color: #fff;
  font-family: "Gruppo";
  padding: 5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
  z-index: 2;
  /* position: relative; */
  /* font-weight: 700; */
  /* margin-top: 8px; */
}

.modal-container h4:hover {
  background: #000;
  color: #fff;
}


.is-dragging {
  filter: blur(20px) contrast(5);
}

.modal-container-header {
  cursor: pointer;
  height: 14px;
  position: absolute;
  top: -14px;
  font-size: 1.5rem;
  color: #fff;
  text-align: right;
  /* border: 1px solid #fff; */
  /* background: #00f; */
  width: calc(100% + 2px);
  left: -1px;
  /* padding-right: 14px; */
  box-sizing: border-box;
  font-family: "Gruppo";
  font-weight: 700;
}

.toggle-modal-btn {
  cursor: pointer;
  z-index: 9;
  position: fixed;
  background: #fffa;
  font-family: "Gruppo";
  width: 80px;
  font-weight: 900;
  font-size: 1.125rem;
  padding: 4px 2px;
  /* color: #000 !important; */
  left: calc(44px);
  margin: 0;
  top: 100px;
  border: none;
  box-shadow: 0px 0px 24px 0px #fff8;
  border-radius: 4px;
}

.modal-container-header p {
  /* margin-right: 2rem; */
  font-family: "Index - Katalog";
  position: absolute;
  /* background: #fff; */
  /* color: #666; */
  font-weight: 700;
  /* border: 1px solid #707070; */
  font-size: 1.25rem;
  padding: 0;
  width: 14px;
  height: 14px;
  top: -20px;
  float:right;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal.option {
  display: inline-block;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  transition: 0.2s;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1434A4;
}


::-webkit-scrollbar:hover {
  width: 10px;
}
