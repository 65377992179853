.post-grid-container {
  margin: auto;
  border: 1px solid #f00;
  overflow: auto;
  height: calc(100vh - 102px);
  display: flex;
  align-items: center;
}

.post-grid-container .grid-image-container {
  min-height: 180px;
}

.post-grid-container.compact-grid-container {
  /* width: 760px; */
}

.post-grid-container a.post-single-link {
  color: #000;
  text-decoration: none;
}

.post-grid-container a.post-single-link h3 {
    font-size: 1.25rem;
    margin: 0;
    padding: 0 14px;
    border: 1px solid #000;
    height: 60px;
    font-family: 'NT Wagner';
}

.grid-header-container {
  border: 1px solid #0f0;
  height: 102px;
}

.grid-image-container {
  border: 1px solid #000;
  background: #f1f1f1;
  width: calc(100% - 28px);
  box-sizing: border-box;
  margin: 0px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

}
