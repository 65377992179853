.pagination-container {
  list-style-type: none;
  text-align: center;
}

.pagination-item {
  display: inline;
  margin-right: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.active-pagination-item {
  background: #000;
  color: #fff;
}

.floating-pagination-container {
  position: absolute;
  bottom: 7px;
  width: 100%;
  padding: 0;
}
